import { Item, Page } from './App';  // Updated import
import { API_URL } from './constants';

export const fetchItems = async (pageName: string): Promise<Item[]> => {
  try {
    const response = await fetch(`${API_URL}/api/mootel/items/${pageName}`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const items = await response.json();
    return Array.isArray(items) ? items : [];
  } catch (error) {
    console.error('Error fetching items:', error);
    return []; // Return an empty array if there's an error
  }
};

export const addItem = async (pageName: string, url: string): Promise<Item> => {
  const response = await fetch(`${API_URL}/api/mootel/items/${pageName}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ url }),
  });
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const newItem = await response.json();
  // Ensure the name is set to the extracted title if available
  return { ...newItem, name: newItem.name || newItem.title || 'Untitled' };
};

export const updateItem = async (pageName: string, item: Item): Promise<Item> => {
  const encodedPageName = encodeURIComponent(pageName);
  const response = await fetch(`${API_URL}/api/mootel/items/${encodedPageName}/${item._id}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(item),  // Send the item as is, MongoDB will handle the _id
  });
  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
  }
  return response.json();
};

export const deleteItem = async (pageName: string, id: string): Promise<void> => {
  const response = await fetch(`${API_URL}/api/mootel/items/${pageName}/${id}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  });
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
};

export const testConnection = async (): Promise<void> => {
  const response = await fetch(`${API_URL}/test-connection`);
  if (!response.ok) {
    throw new Error(`MongoDB connection test failed: ${response.statusText}`);
  }
};

export const fetchPages = async (): Promise<Page[]> => {
  try {
    const response = await fetch(`${API_URL}/api/mootel/pages`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const pages = await response.json();
    return Array.isArray(pages) ? pages : [];
  } catch (error) {
    console.error('Error fetching pages:', error);
    return []; // Return an empty array if there's an error
  }
};


export const addPage = async (pageName: string): Promise<Page> => {
  try {
    const response = await fetch(`${API_URL}/api/mootel/pages`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name: pageName }),
    });
    
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message}`);
    }
    
    return response.json();
  } catch (error) {
    console.error('Error in addPage:', error);
    throw error;
  }
};

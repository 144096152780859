import React, { useState } from 'react';

import { Item } from '../App';  // Updated import
import { Button } from './ui/button';
import { Calendar } from './ui/calendar';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from './ui/card';
import { Input } from './ui/input';
import { Textarea } from './ui/textarea';

interface ItemCardProps {
  item: Item;
  onUpdate: (item: Item) => void;
  onDelete: (id: string) => void; 
}

export const ItemCard: React.FC<ItemCardProps> = ({ item, onUpdate, onDelete }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedItem, setEditedItem] = useState<Item>(item);

  const handleSave = () => {
    onUpdate(editedItem);
    setIsEditing(false);
  };

  const handleCardClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // Prevent navigation if clicking on buttons or inputs
    if (
      e.target instanceof HTMLButtonElement ||
      e.target instanceof HTMLInputElement ||
      e.target instanceof HTMLTextAreaElement ||
      (e.target as HTMLElement).closest('button') ||
      (e.target as HTMLElement).closest('input') ||
      (e.target as HTMLElement).closest('textarea')
    ) {
      return;
    }
    window.open(item.url, '_blank');
  };

  return (
    <Card className="card" onClick={handleCardClick}>
      <CardHeader>
        <CardTitle>
          {isEditing ? (
            <Input
              value={editedItem.name}
              onChange={(e) => setEditedItem({ ...editedItem, name: e.target.value })}
              placeholder="Item Name"
              className="mb-2"
            />
          ) : (
            item.name || 'Untitled'
          )}
        </CardTitle>
        <CardDescription>{item.url}</CardDescription>
      </CardHeader>
      <CardContent>
        {item.image ? (
            <img src={item.image} alt={item.name} className="w-full h-[200px] object-cover mb-4" />
          ) : (
            <img src="/api/placeholder/350/200" alt={item.name} className="w-full h-[200px] object-cover mb-4" />
          )}
          {isEditing ? (
          <>
            <Textarea
              value={editedItem.notes}
              onChange={(e) => setEditedItem({ ...editedItem, notes: e.target.value })}
              placeholder="Notes"
              className="mb-2"
            />
            <Input
              value={editedItem.location}
              onChange={(e) => setEditedItem({ ...editedItem, location: e.target.value })}
              placeholder="Location"
              className="mb-2"
            />
            <Calendar
              mode="single"
              selected={editedItem.date instanceof Date ? editedItem.date : new Date(editedItem.date)}
              onSelect={(date: Date | undefined) => setEditedItem({ ...editedItem, date: date || new Date() })}
              className="mb-2"
            />
          </>
        ) : (
          <>
            <p>{item.notes}</p>
            <p>Location: {item.location}</p>
            <p>Date: {item.date instanceof Date ? item.date.toDateString() : new Date(item.date).toDateString()}</p>
          </>
        )}
      </CardContent>
      <CardFooter className="flex justify-between">
        {isEditing ? (
          <Button onClick={handleSave}>Save</Button>
        ) : (
          <Button onClick={() => setIsEditing(true)}>Edit</Button>
        )}
        <Button variant="destructive" onClick={() => onDelete(item._id.toString())}>Delete</Button>
      </CardFooter>
    </Card>
  );
};
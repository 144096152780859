import React, { useState } from 'react';

import { Loader2 } from 'lucide-react';

import { Button } from './ui/button';
import { Input } from './ui/input';

interface AddItemFormProps {
  onAddItem: (url: string) => Promise<void>;
}

export const AddItemForm: React.FC<AddItemFormProps> = ({ onAddItem }) => {
  const [newHotelUrl, setNewHotelUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await onAddItem(newHotelUrl);
      setNewHotelUrl('');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex mb-4">
      <Input
        type="text"
        value={newHotelUrl}
        onChange={(e) => setNewHotelUrl(e.target.value)}
        placeholder="Paste hotel URL here"
        className="flex-grow mr-2"
      />
      <Button type="submit" disabled={isLoading}>
        {isLoading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : 'Add Moo'}
      </Button>
    </form>
  );
};
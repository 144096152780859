import React, { useState } from 'react';

import { Button } from './ui/button';
import { Input } from './ui/input';

interface SidebarProps {
  pages: { _id: string; name: string }[];
  currentPage: string;
  onPageChange: (pageName: string) => void;
  onAddPage: (pageName: string) => void;
}

export const Sidebar: React.FC<SidebarProps> = ({ pages, currentPage, onPageChange, onAddPage }) => {
  const [newPageName, setNewPageName] = useState('');

  const handleAddPage = () => {
    if (newPageName.trim()) {
      onAddPage(newPageName.trim());
      setNewPageName('');
    }
  };

  return (
    <div className="w-64 bg-gray-100 p-4 h-screen overflow-auto">
      <h2 className="text-xl font-bold mb-4">Mootel</h2>
      {pages.map((page) => (
        <Button
          key={page._id}
          className={`w-full mb-2 ${currentPage === page.name ? 'bg-blue-500 text-white' : ''}`}
          onClick={() => onPageChange(page.name)}
        >
          {page.name}
        </Button>
      ))}
      <div className="mt-4">
        <Input
          type="text"
          value={newPageName}
          onChange={(e) => setNewPageName(e.target.value)}
          placeholder="New page name"
          className="mb-2"
        />
        <Button onClick={handleAddPage} className="w-full">
          Create New Page
        </Button>
      </div>
    </div>
  );
};
import './styles/globals.css';
import './App.css';

import React, { useEffect, useState } from 'react';

import {
  addItem,
  addPage,
  deleteItem,
  fetchItems,
  fetchPages,
  updateItem,
} from './api';
import { AddItemForm } from './components/addItemForm';
import { ItemCard } from './components/itemCard';
import { Sidebar } from './components/sidebar';
import { Button } from './components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from './components/ui/card';
import { Input } from './components/ui/input';

export interface Item {
  _id: string;
  name: string;
  url: string;
  notes: string;
  date: Date | string;
  image: string;
  location: string;
}

export interface Page {
  _id: string;
  name: string;
}

// Simple hash function
const simpleHash = (str: string): number => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash; // Convert to 32-bit integer
  }
  return hash;
};

// Hashed version of 'grace'
const HASHED_PASSWORD = simpleHash('grace');

const App: React.FC = () => {
  const [items, setItems] = useState<Item[]>([]);
  const [pages, setPages] = useState<Page[]>([]);
  const [currentPage, setCurrentPage] = useState<string>('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');

  useEffect(() => {
    const initializeApp = async () => {
      try {
        const fetchedPages = await fetchPages();
        setPages(fetchedPages);
        if (fetchedPages.length > 0) {
          setCurrentPage(fetchedPages[0].name);
        }
      } catch (error) {
        console.error('Error initializing app:', error);
      }
    };

    initializeApp();
  }, []);

  useEffect(() => {
    if (currentPage && isAuthenticated) {
      fetchItems(currentPage).then(setItems).catch(console.error);
    }
  }, [currentPage, isAuthenticated]);

  const handleAddItem = async (url: string) => {
    try {
      const newItem = await addItem(currentPage, url);
      setItems(prevItems => [...prevItems, newItem]);
    } catch (error) {
      console.error('Error adding item:', error);
    }
  };

  const handleAddPage = async (pageName: string) => {
    try {
      const newPage = await addPage(pageName);
      setPages([...pages, newPage]);
      setCurrentPage(pageName);
    } catch (error) {
      console.error('Error adding page:', error);
    }
  };

  const handleUpdateItem = async (updatedItem: Item) => {
    try {
      const updated = await updateItem(currentPage, updatedItem);
      setItems(items.map(item => item._id === updated._id ? updated : item));
    } catch (error) {
      console.error('Error updating item:', error);
    }
  };

  const handleDeleteItem = async (id: string) => {
    try {
      await deleteItem(currentPage, id);
      setItems(items.filter(item => item._id !== id));
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();
    if (simpleHash(password) === HASHED_PASSWORD) {
      setIsAuthenticated(true);
    } else {
      alert('Incorrect password');
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Card className="w-[350px]">
          <CardHeader>
            <CardTitle>Open Sesame</CardTitle>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleLogin}>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter password"
                className="mb-4"
              />
              <Button type="submit" className="w-full">Enter</Button>
            </form>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <div className="flex h-screen">
      <Sidebar 
        pages={pages} 
        currentPage={currentPage} 
        onPageChange={setCurrentPage}
        onAddPage={handleAddPage}
      />
      <div className="flex-1 p-4 overflow-auto">
        <h1 className="text-2xl font-bold mb-4">{currentPage}</h1>
        <AddItemForm onAddItem={handleAddItem} />
        <div className="flex flex-wrap">
          {items.map(item => (
            <ItemCard 
              key={item._id} 
              item={item} 
              onUpdate={handleUpdateItem} 
              onDelete={handleDeleteItem} 
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default App;